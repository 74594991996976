<template>
  <div class="page-container">
    <van-skeleton title :row="20" :loading="articleLoading">
      <div class="article-title" v-if="articleTitle">
        <p>{{articleTitle}}</p>
      </div>
      <div class="article-status" v-if="stats && articleStats">
        <van-row>
          <van-col :span="6" :offset="3">
            <p class="article-stats">
              <svg-icon icon-class="date" /> {{articleStats.date}}</p>
          </van-col>
          <van-col :span="6">
            <p class="article-stats">
              <svg-icon icon-class="eye-open" /> {{articleStats.view}}</p>
          </van-col>
          <van-col :span="6">
            <p class="article-stats">
              <svg-icon icon-class="comment" /> {{articleStats.comment}}</p>
          </van-col>
        </van-row>
      </div>

      <div class="article-content">
        <article v-html="articleContent"></article>
      </div>

      <sdut-banner />
    </van-skeleton>
  </div>
</template>

<script>
import SdutBanner from '@/components/SdutBanner'
import { getNewsData } from '@/api/news'
import wxShare from '@/utils/share'
import {getStorage} from '@/utils/storage'
export default {
  components: { SdutBanner },
  data () {
    return {
      articleId: null,
      articleContent: null,
      articleLoading: true,
      articleTitle: null,
      articleStats: null
    }
  },
  props: {
    stats: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  mounted () {
    this.articleId = this.$route.params.article_id
    this.fetchData()
  },
  methods: {
    fetchData () {
      getNewsData(this.articleId).then(response => {
        this.articleContent = response.content
        this.articleTitle = response.title
        document.title = response.title
        this.articleStats = {
          date: response.date,
          view: response.view,
          comment: null
        }
        this.articleLoading = false
        wxShare.updateWxShareConfig({
          title: response.title,
          desc: getStorage('name') + '邀请您查看该文章 —— 山东理工大学教育发展基金会',
          link: window.location.href
        })
      })
    }
  }
}
</script>

<style>
.article-content {
  background-color: #ffffff;
  padding: 1rem 1rem;
}

.article-status {
  background-color: #ffffff;
}

.article-title {
  background-color: #ffffff;
}

.article-title p {
  margin: 0;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: bold;
}

.article-content article {
  font-size: 1rem;
  text-indent: 1rem;
}

.article-stats {
  text-align: center;
  font-size: 0.8rem;
}
</style>
