/* eslint-disable */
import { getJssdkParam } from "@/api/other";

const signMap = new Map();
const defaultWxShareConfig = {
    title: "山东理工大学基金会",
    desc: '分享',
    link: location.href,
    imgUrl: 'https://donate.isdut.cn/static/sdutlogo180.jpg',
    jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline'],
}
const wxShareConfig = {

}

const wxShare = {
    updateWxShareConfig(config = {}) {
        console.log("start wx jssdk share")
        wxShareConfig.title = config.title || defaultWxShareConfig.title;
        wxShareConfig.desc = config.desc || defaultWxShareConfig.desc;
        wxShareConfig.link = config.link || defaultWxShareConfig.link;
        wxShareConfig.imgUrl = config.imgUrl || defaultWxShareConfig.imgUrl;
        wxShareConfig.jsApiList = config.jsApiList || defaultWxShareConfig.jsApiList;

        let authUrl = wxShareConfig.link.split("#")[0];

        if (signMap.has(authUrl)) {
            this._wxConfigJSSDK(signMap.get(authUrl), wxShareConfig);
        } else {
            this._wxShareAuth(authUrl);
        }

    },

    //从服务器获取某分享链接的签名信息，并存储起来
    _wxShareAuth(authUrl) {
        //此处我使用的是自己封装的网络请求方法
        getJssdkParam(encodeURIComponent(authUrl)).then(response => {
            const sign = response;
            signMap.set(authUrl, sign);
            this._wxConfigJSSDK(sign);
        })
    },

    //将签名信息更新到微信的SDK中
    _wxConfigJSSDK(shareSign) {
        wx.config({
            debug: false,
            appId: shareSign.appId,
            timestamp: shareSign.timestamp,
            nonceStr: shareSign.nonceStr,
            signature: shareSign.signature,
            jsApiList: shareSign.jsApiList
        })
        wx.ready(function () {
            const { title, desc, link, imgUrl } = wxShareConfig;
            wx.onMenuShareAppMessage({
                title,
                desc,
                link,
                imgUrl,
                success: function () {
                    console.log("分享成功");
                },
                fail: function () {
                    console.log("分享失败");
                },
                cancel: function () {
                    console.log("取消分享");
                }
            })
            
            wx.onMenuShareTimeline({
                title,
                link,
                imgUrl,
                success: function () {
                    console.log("分享成功");
                },
                cancel: function () {
                    console.log("取消分享");
                }
            })

        });

        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。	
        wx.error(function (res) {
            console.log("分享失败: error", res);
        });

    }
}

export default wxShare